import React from 'react';
import Provider from './src/contexts/GlobalContext';
import { appInsights } from './src/AppInsights';
import './src/styles/global.css';

export const onRouteUpdate = () => {
  if (appInsights) {
    appInsights.trackPageView();
  }
};

export const wrapRootElement = (props) => {
  const { element } = props;
  return <Provider>{element}</Provider>;
};
