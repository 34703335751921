// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-submission-[id]-tsx": () => import("./../../../src/pages/submission/[id].tsx" /* webpackChunkName: "component---src-pages-submission-[id]-tsx" */),
  "component---src-pages-submissions-board-[abbreviation]-tsx": () => import("./../../../src/pages/submissions/board/[abbreviation].tsx" /* webpackChunkName: "component---src-pages-submissions-board-[abbreviation]-tsx" */),
  "component---src-pages-submissions-tsx": () => import("./../../../src/pages/submissions.tsx" /* webpackChunkName: "component---src-pages-submissions-tsx" */),
  "component---src-pages-user-manual-tsx": () => import("./../../../src/pages/user-manual.tsx" /* webpackChunkName: "component---src-pages-user-manual-tsx" */)
}

