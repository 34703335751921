import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { SnackbarMessageOptions } from '@eisc-frontend/shared-ui';

export const GlobalContext = createContext<{
  message: any;
  showMessage: Dispatch<SetStateAction<SnackbarMessageOptions | undefined>>;
}>({
  message: undefined,
  showMessage: (messageOptions) => {},
});

export const Provider = (props: {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
}) => {
  const { children } = props;
  const [message, showMessage] = useState<SnackbarMessageOptions | undefined>();

  const globalContext = {
    message,
    showMessage,
  };

  return <GlobalContext.Provider value={globalContext}>{children}</GlobalContext.Provider>;
};

export const { Consumer } = GlobalContext;

export default Provider;
